import React from "react";
import { isChrome, isFirefox, isIeEdge, getBrowser } from "@tightrope/lpscripts/browserdetect";
import Styles from "./css/saflp3page.module.scss";
import Data from "./data/saflp3page.json";
import CtaDisclosureCb3 from "src/components/ctadisclosurecb3"

class saflp3page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: "none",
      pageData: props.data,
      data: Data,
      isLoading: true
    };
  }

  showBrowsers(browsers) {
    let display = false;
    if (getBrowser() === "ie" && browsers.includes("ie")) {
      display = true;
    }
    if (getBrowser() === "firefox" && browsers.includes("firefox")) {
      display = true;
    }
    if (getBrowser() === "chrome" && browsers.includes("chrome")) {
      display = true;
    }
    if (getBrowser() === "edge" && browsers.includes("edge")) {
      display = true;
    }
    if (getBrowser() === "other" && browsers.includes("other")) {
      display = true;
    }
    if (display) this.setState({ display: "block" });
    else this.setState({ display: "none" });
  }

  componentDidMount() {
    if (this.props.browsers) {
      this.showBrowsers(this.props.browsers);
    } else {
      this.setState({ display: "block" });
    }
    if (this.props.data) {
      this.props.data.then((data) => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData });
        this.state.isChrome = isChrome();
        this.state.isFirefox = isFirefox();
        this.state.isIeEdge = isIeEdge();

        if (isChrome()) {
          const browserData = Object.assign(this.state.data, { button: this.state.data.chrome.button });
          this.setState({ data: browserData });
        } else if (isIeEdge()) {
          const browserData = Object.assign(this.state.data, { button: this.state.data.edge.button });
          this.setState({ data: browserData });
        } else if (isFirefox()) {
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({ data: browserData });
        }
      });
    }

    window.addEventListener("load", () => {
      this.setState({ isLoading: false });
    });

      //OS Detection
      document.querySelectorAll('.animCTA').forEach(p => {

          p.innerText = this.state.data.button;
  });

  }



  startAnimation() {
    if (isChrome() || isIeEdge()) {
      return;
    }
    
    var stepTrack = document.querySelector('.saflp3page-module--stepTrack');
    stepTrack.classList.add('saflp3page-module--active');

    var listItems = document.querySelectorAll('li');
    if (listItems.length >= 1) {
      listItems[0].classList.add('saflp3page-module--stepComplete');
      listItems[0].classList.remove('saflp3page-module--isActive');
    }

    if (listItems.length >= 2) {
      listItems[1].classList.add('saflp3page-module--isActive');
    }

    const wrapper = document.querySelector(`#${Styles.wrapper}`);
    if (wrapper) {
      wrapper.style.transition = 'opacity 0.5s ease-in-out';
      wrapper.style.opacity = '0';
    }

    const secondPage = document.querySelector(`.${Styles.secondPage}`);
    if (secondPage) {
      secondPage.style.visibility = 'visible';
      secondPage.style.opacity = '1';
      secondPage.style.pointerEvents = 'auto';
      secondPage.style.transition = 'opacity 0.5s ease-in-out, visibility 0s linear 0s';
    }
  }

  render() {
    return (
      <>
        <div id={Styles.saflp3page}>
          <div className={`${Styles.preloader} ${this.state.isLoading ? Styles.isLoading : ""}`}>
            <div className={Styles.loader}>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
            </div>
          </div>
          <main id={Styles.wrapper}>
            <section>
              <h1 className = "hc_param">{this.state.data.headline}</h1>
              <h2>{this.state.data.subheadline}</h2>
              <div className={Styles.compatible}>
                <img alt="checkbox" className={Styles.checkBox} src={this.state.data.checkIcon} />
                <h3>{this.state.data.compatible}</h3>
              </div>
              <button 
                className={`${Styles.animCTA} cl1 saf_dl`} 
                data-cy="cta" 
                onClick={() => {
                  if (!isChrome() && !isIeEdge()) {
                    this.startAnimation();
                    window.triggerInstall();
                  }
                }}
                style={{
                  cursor: isChrome() || isIeEdge() ? 'not-allowed' : 'pointer'
                }}
              >
                {this.state.data.button}
              </button>
              <CtaDisclosureCb3></CtaDisclosureCb3>
              <div className={Styles.stepContainer}>
                <div className={Styles.stepTrack}>
                  <div className={Styles.blueLine}></div>
                </div>
                <ul>
                  <li className={Styles.isActive}>Step One</li>
                  <li>Step Two</li>
                </ul>
              </div>
            </section>
          </main>
          <div className={Styles.secondPage}>
          <img alt="logo" className={Styles.secondLogo} src={this.state.data.logo} />
          <p className={Styles.secondHeadline}>{this.state.data.secondHeadline}</p>
          <div className={Styles.secondSteps}>
            <ul>
              <li>
                <p className={Styles.stepText}>{this.state.data.step1Text}</p>
                <img alt="step1" src={this.state.data.img1} />
              </li>
              <li>
                <p className={Styles.stepText}>{this.state.data.step2Text}</p>
                <img alt="step2" src={this.state.data.img2} /> 
              </li>
              <li>
                <p className={Styles.stepText}>{this.state.data.step3Text}</p>
                <img alt="step3" src={this.state.data.img3} />
              </li>
            </ul>
          </div>
          <p className={Styles.tryAgain}>{this.state.data.secondTryAgain1} <a href="https://apps.apple.com/lt/app/easy-print-web-pages/id6736956854" target="_blank">{this.state.data.secondTryAgain2}</a></p>
          <footer>
            <ul>
              <li>Tightrope Interactive</li>
              <li>
                <a href="https://easyprint.app/terms-of-service/" target="_blank">Terms</a>
              </li>
              <li>
                <a href="https://easyprint.app/privacy-policy/" target="_blank">Privacy</a>
              </li>
              <li>
                <a href="https://easyprint.app/uninstall/" target="_blank">Uninstall</a>
              </li>
              <li>
                <a href="https://easyprint.app/contact-us/" target="_blank">Unsubscribe</a>
              </li>
              <li>
                <a href="https://easyprint.app/contact-us/" target="_blank">Contact</a>
              </li>
              <li>All trademarks are property of their respective owners</li>
              <li>
                <a href="https://easyprint.app/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a>
              </li>
            </ul>
          </footer>
          </div>
        </div>
      </>
    );
  }
}

export default saflp3page;
