import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Saflp3page from "src/components/saflp3page"
import ChunkyFooter from "src/components/chunkyfooter"
import CtaDisclosureCb3 from "src/components/ctadisclosurecb3"
import Disclaimers from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const GWlpData = import("./data/data.json");
const footerData = import("./data/footerData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Easy Print | Print from Anywhere</title>
    <style type="text/css">{`
       #ctadisclosurecb3-module--ctadisclaimer {
        margin:0 auto !important;
        font-size:13px;
        width:500px;
       }

       #ctadisclosurecb3-module--ctadisclaimer p {
        font-size:12px;
        line-height:125%;
       }
        #disclaimers-module--disclaimers {
        width:100%;
        }
  
       #disclaimers-module--disclaimers p {
        font-weight: 300;
        color: #333;
        font-size: 13px;
        text-align: center;
        font-family: Roboto,sans-serif;
        max-width: 580px;
        margin: 0 auto;
        }
  
        #disclaimers-module--disclaimers p a {
        font-weight:700;
        }
        footer {
        background:#fff;
        font-family: 'Roboto', sans-serif !important;
        display: block;
        position: relative;
        color: #333;
        border-radius: 5px;
        width: 100%;
      }
footer {
        background:#fff;
        font-family: 'Roboto', sans-serif !important;
        display: block;
        position: relative;
        color: #333;
        border-radius: 5px;
        width: 100%;
      }
      footer ul {
        position: relative;
        width:600px;
        list-style: none;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: right;
        color: #333;
        font-size: 12px;
        padding: 12px 0;
      }
      footer ul li {
        padding: 0 4px 0 0;
      }
      footer ul li:last-child {
        padding: 0;
      }
      footer ul li:after {
        content: '|';
        padding: 0 0 0 4px;
      }
      footer ul li:nth-child(6):after, footer ul li:last-child:after {
        content: none;
        padding: 0;
      }
      footer ul a {
        color: #333;
      }
      @media only screen and (max-width: 768px) {
    
        footer {
          position: relative;
          display: flex;
          justify-content:center;
          align-items:center;
          color: #333;
          width: 100%;
          right:auto;
          bottom:auto;
      }
    
      footer ul {
        position: relative;
        display: flex;
        justify-content:center;
        align-items:center;
    }

    `}
    </style>
    </Helmet>
    <section>
      <Saflp3page data={GWlpData}></Saflp3page>
      <ChunkyFooter data={footerData}><Disclaimers language='en-safari-new'></Disclaimers></ChunkyFooter>
      </section>
      <RawFooter></RawFooter>
    </HomepageLayout>
  )
  }
